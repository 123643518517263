<!-- Start Login Area -->

<div class="col-md-12"
    style=" background-color: #f4f4f4; display:inline-flex; position: fixed; top: 0px;left: 0px; width: 100% ; z-index: 9999999; bottom:0px;">
    <div class="spin_block" *ngIf="isload">
        <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="col-md-6 mhide " style="padding: 40px;">
        <img src="assets/img/HR_bg.png">
    </div>
    <div class="col-md-6 " style="background-color: white; ">
        <div style="max-width: 300px; padding-top: 50px;margin: auto;">
            <div style="text-align: center;">
                <img style="width: 250px;" src="assets/img/Login_Logo.png" alt="image">
                <div style="font-size: 23px;
                  text-align: center;
                margin-top: 15px;
                  color:#153a6f;;
                  text-transform: uppercase;
                  font-weight: 800;">HR Payroll Management System</div>
                <br>
            </div>
            <div style="font-size: 1.5rem;
                text-align: center;
                line-height: 1.45;
                color: #6e6b7b;
                margin: 16px 0;
                font-weight: 600;">Login</div>
            
                <div class="form-group">
                    <label>User Name</label>
                    <input type="text" style="background-color: #f8f9fa0d; border-radius: 5px!important;"
                        class="form-control" (keyup.enter)="focus_Next()" [(ngModel)]="addD.Username" name="Username" #Username="ngModel">
                </div>
                <div class="form-group">
                    Password
                    <input type="password" style="background-color: #f8f9fa0d; border-radius: 5px!important;"
                        class="form-control password" (keyup.enter)="login()" [(ngModel)]="addD.Password" name="Password" #Password="ngModel" required>
                </div>
                <div class="form-group">
                    <span class="star" style="color: #fb2105;">{{error_}}</span>
                </div>
                <div style="text-align: right;" class="hidden">
                    <span class="star" style="color: #0d6efd;
                        line-height: 45px;" (click)="get_Password()">Forgot Password ?<br></span>
                </div>
                <button type="button" (click)="login()" style="width: 100%; background-color: #0055aa;
                    border-color: #0055aa;" [disabled]="btndisable" class="btn btn-info">
                    <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Login</button>
            
        </div>

    </div>
</div>
<!-- End Login Area -->