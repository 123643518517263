import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

declare let $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isload: boolean = false;
  data: any = {}
  constructor(public http: HttpClient, public appservice: AppService, private toastr: ToastrService, public router: Router) {

    if (this.getData()) {

      this.isload = true;
      this.data = JSON.parse(localStorage.getItem('User_Data'));
      appservice.Current_User = this.data;
      appservice.Company = "_" + this.data.UM_Company;
      this.get_Token();

    }

  }
  get_Password() {
    return this.router.navigate(['/forgot-new-password']);

  }
 
 
  get_Token() {

    this.appservice.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.appservice.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.appservice.headers })
      .subscribe(
        (val) => {
          this.appservice.tocken = val['access_token'];
          this.get_Field_Setting();
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }


  Prow = [];
  page = "";


  get_Field_Setting() {
    this.appservice.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {

      if (res == "No_Licence") {
        this.router.navigate(['/invalid-licence']);
      }
      else {
        this.appservice.Field_Setting = JSON.parse(res).record;
        this.get_Reference();
      }
    });


  }


  get_Reference() {


    this.appservice.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.appservice.Reference_Rows = JSON.parse(res).record;

      this.appservice.Reference_Rows.sort((a, b) => a["label"].localeCompare(b["label"]))

  
      this.appservice.CREATED_BY = this.data.UM_User_Name;
      this.appservice.Rights_ID = this.data.UM_Rights;


      this.appservice.Emp_ID = this.data.Emp_ID;

      this.appservice.Emp_Name = this.data.UM_Full_Name;

      
      this.appservice.get_Role_Rights(this.data.UM_Rights);

      try {
        this.Prow = this.appservice.Reference_Rows.filter(e => e.Ref_ID == "User_Role");
        
        this.appservice.Rights_Name = this.Prow.filter(e => e.RGV_iID.toLowerCase() == this.data.UM_Rights)[0].RGV_vDesciption;
        this.appservice.U_Rights = this.appservice.Rights_Name.toLowerCase();

        this.appservice.Header_Disp = this.data.CM_Name;

      } catch { }




      try {

        this.page = this.Prow.filter(e => e.RGV_iID.toLowerCase() == this.data.UM_Rights)[0].RGV_vCode;
        this.appservice.Start_Page = this.page;
        this.router.navigate([this.page]);
      } catch {
        this.router.navigate([this.page]);
      }
    });
  }



  focus_Next() {
    $(".password").focus();
  }
  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }
  public btndisable: boolean = false;
  public addD: any = {};
  rows = [];

  login() {

    this.btndisable = true;
    this.appservice.get("Api/Common/get_Lodge_Login?Username=" + this.addD.Username + "&Password=" + this.addD.Password).subscribe((res: any) => {


      this.btndisable = false;

      if (res != "") {
        this.error_ = "";
        this.rows = JSON.parse(res).record;
        
        this.appservice.Company = "_" + this.rows[0]["UM_Company"];
        localStorage.setItem('User_Data', JSON.stringify(this.rows[0]));
        window.location.href = "\\";


      }
      else {

        this.error_ = "Invalid User Name and Password";
        this.toastr.error("Invalid User Name and Password", "Error", { timeOut: 3000 });

      }

    });
  }
  error_ = "";

  ngOnInit() {
  }

}
