import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';

import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { interval } from 'rxjs';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";

  header_hide: boolean = false
  public Server_URL = "http://localhost:5837/";
  public Start_Page = "";
  public Quotation_Format = "Format1";
  public System_mac_address = "Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Print_Format = "Format1"

  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public NT_Sales_Disp_Text1 = "Open Invoice";

  public Link = "";
  public CM_Name = "";
  public CM_Email_ID = "";
  public Purchase_entry_page = "/Purchase/purchase-entry";
  public Sales_Disp_Text1 = "Open Invoice";
  public Sales_Disp_Text2 = "Open Invoice(with code)";
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;


  public key_value = "";

  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:5837/";



  public Cam_Type = "Proof";
  public Proof_Image: any;
  public Visa_Image: any;
  public Empty_Image: any;

  public SelectedMonth;
  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;



  public Ledger_Type = "1";
  public U_Rights = ""
  public Item_Group_ = "1";


  public isadd_Page: boolean = false;
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));


      this.Company = "_" + this.data.UM_Company;

    }




    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");
    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.load_themes('');
  }


public selectedDate;

  get_time() {
    this.T_Date_TimeD = this.datePipe.transform(new Date(), "dd-MM-yyyy hh:mm ");
  }


  get_dt(date) {
    return this.datePipe.transform(date, "dd-MMM-yyyy hh:mm ");
  }
  get_Month(date) {
    return this.datePipe.transform(date, "yyyy-MM-");
  }


  public Header_Disp = "";


  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "_7";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Selected_Row: any = {};
  public Default_ref_ID: string = "State";
  public URL_Location = "";
  public Hotel_Reprint = "false";
  public Form_Name = "All";
  public Condi_Sales = "";
  public Shift = "All";

  public Checkin_Room_No = "";
  public Room_CIN_ID = "";

  public disp_category: boolean = false;

  public Sales_Entry_Row_width =
    {
      ID: "5%",
      Code: "10%",
      Name: "23%",
      Desc: "17%",
      Rate: "15%",
      Qty: "15%",
      Amount: "15%"
    };


  public SER_Disp =
    {
      ID: true,
      Code: false,
      Name: true,
      Desc: true,
      Rate: true,
      Qty: true,
      Amount: true
    };



  public Sales_Temp_Row: any = {};
  public Sales_Temp_Row1 = [];
  public Proforma_Details_GF = ['Customer_Name', 'Bill_No'];
  public BOM_Master_GF = [];

  public Quatation_Details_GF = [];
  public Ledger_Master_GF = [];
  public Visitor_Master_GF = ['Contact_No'];

  public Item_Master_GF = [];
  public Product_Master_GF = [];
  public Purchase_Master_GF = [];
  public Stock_Adjustment_GF = [];
  public Blocked_Visitor_GF = [];
  public Area_Master_GF = [];
  public Purchase_Order_Details_GF = [];
  public Member_Details_GF = [];
  public Area_Street_Details_GF = [];
  public Production_Details_GF = [];
  public Employee_Details_GF = [];
  public Vadi_Details_GF = [];
  public Holiday_Details_GF = [];
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];

  public Expense_Details_GF = ['Category_', 'Narration1', 'Amount'];
  public Contra_Details_GF = ['Naration', 'Amount'];

  public Sales_Details_GF = ['Customer_Name', 'Bill_No', 'Area'];
  public Delivery_Details_GF = ['Customer_Name', 'DC_No'];







  public Communication_Menu = [
    { Menu: 'WhatsApp', Link: '/settings/whatsapp-setting' },
    { Menu: 'Mail', Link: '/settings/gmail-setting' },
    { Menu: 'SMS', Link: '/settings/sms-setting' },
    { Menu: 'Message', Link: '/settings/message' },
  ];
  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/settings/user-details' },
    { Menu: 'Role Master', Link: '/settings/role-master' },
    { Menu: 'Role Rights', Link: '/settings/role-permissions' }
  ];

  public EPF_Records_Menu = [
    { Menu: 'Form 3A', Link: '/report/form3a' },
    { Menu: 'Form 12', Link: '/report/form12' },
    { Menu: 'Form 6', Link: '/report/form6' },
    { Menu: 'Salary Register', Link: '/report/salary-register' },
    { Menu: 'EPF challan', Link: '/report/epf-challan' },
  ];
  public ESI_Records_Menu = [
    { Menu: 'ESI Form 7', Link: '/report/esi-form7' },
    { Menu: 'ESI Form 6', Link: '/report/esi-form6' },
    { Menu: 'ESI Challan', Link: '/report/esi-challan' },
    { Menu: 'Accident Report', Link: '/report/accident-report' },
  ];
  public Labour_Records_Menu = [
    { Menu: 'Form I', Link: '/report/form-i' },
    { Menu: 'Form IV-C', Link: '/report/form-iv-c' },
    { Menu: 'Form XV', Link: '/report/form-xv' },
    { Menu: 'Form IVEE', Link: '/report/form-ivee' },
    { Menu: 'Form IVF', Link: '/report/form-ivf' },
    { Menu: 'Form V', Link: '/report/formv' },
    { Menu: 'Form B', Link: '/report/form-b' },
    { Menu: 'Form D', Link: '/report/form-d' },
    { Menu: 'Form A', Link: '/report/form-a' },

  ];
  public User_Master_Export = [
    { Field: 'Code', Name: 'Code', Align: '' },
    { Field: 'Name', Name: 'Name', Align: '' },
    { Field: 'User_ID', Name: 'User_ID', Align: '' },
    { Field: 'Password', Name: 'Password', Align: '' },
    { Field: 'Rights', Name: 'Rights', Align: '' },


  ];














  public Ledger_Master_Export = [];
  public Employee_Details_Export = [];
  public img_visible: boolean = false;


  public Order_enty_type = "Type1";
  public page_Name = "Order_Entry";

  public from_customer: boolean = false;
  public from_customer_Code = "";

  public DF_Paymode = "244";
  public DF_Checkin_by = "40";
  public DF_Checkin_Type = "35";
  public DF_Stay_purpose = "31";

  public C_Sub_Total = 0;
  public C_Delivery_Charges = 0;
  public C_Taxable = 0;
  public C_IGST = 0;
  public C_SGST = 0;
  public C_CGST = 0;
  public C_Tax = 0;
  public C_Total_Payable = 0;
  public C_Savings = 0;

  public Item_Category = [];
  public Selected_item_Category = "";

  public Date;
  public Date1;
  public From_Date;
  public To_Date;
  public Attedance_Type = "All";
  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print = "false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public Question_Status = "All";
  public Emp_Status = "Active";

  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public data: any = {};
  public Search_User = "All";

  public T_Date = "";
  public T_Date_Time = "";
  public T_Date_Time1 = "";
  public T_Date_TimeD = "";

  public Pay_Date = "";


  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }


  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
  public Emp_Name = "";
  public Emp_Dept = "";
  public Emp_Approver = "";
  public Emp_Approver_Name = "";

  public Edit_Enable = "";

  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };

  public Company_Visible;

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  public isEdit: boolean = false;

  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }

  public W_height = 0;
  public W_width = 0;

  lenth: number = 0;
  public order_Rows = [];
  public Orders_sort_by = "order_Date"

  public Invoice_Row_total = 0;
  public Invoice_Row_length = 0;
  public Invoice_Row = [];




  L = [];
  get_Array(data) {
    this.L = data.split(",");

    return this.L;
  }



  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
  public Textdisable: boolean = false;

  Text_Edit(data: boolean) {

    if (data) {
      this.Textdisable = false;  // Disable the switch
    } else {
      this.Textdisable = true; // Enable the switch
    }
  }
  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  public Bill_Format_Row = [];
  get_Bill_Format() {
    this.getc("Api/Setting/get_Bill_Format").subscribe((res: any) => {
      this.Bill_Format_Row = JSON.parse(res).record;

    });

  }

  F_Row = [];
  get_fields_of(Table) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }







  public Menus_Rows: any = {};


  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {

    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;

      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  Company_Rows_all = [];
  public get_Company() {
    this.getc("Api/Common/get_Companys").subscribe((res: any) => {
      this.Company_Rows_all = JSON.parse(res).record;

    });
  }
  DB_Emp_Tab=0;
  DB_Emp_Attendance;

  Search_Company_Rows_all = [];
  public get_Company_Search() {
    this.getc("Api/Common/Get_Companys_Search").subscribe((res: any) => {
      this.Search_Company_Rows_all = JSON.parse(res).record;

    });
  }
  get_ref(data) {

    try {
      return this.Reference_Rows.filter(e => e.Ref_ID == data);
    } catch {

      return [];
    }
  }

  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;
      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");
    });
  }


  public Item_Master_Rows = [];
  public company_master = [];



  loading = false;

  public Perment_Item_Master_Row = [];
  public Reset_Item_Master_Row = [];


  public Item_Orderby_Name = "Category,Item_Name";
  public Item_Orderby_Product = "Item_Name";
  public Hod_ID = "";
  get_Company_Master() {



    this.getc("Api/Common/get_companys").subscribe((res: any) => {
      this.company_master = JSON.parse(res).record;



    });
  }



  public Items_Rows = [];
  public I_Category = "All";
  public I_Category2 = "All";
  public I_Ledger_Name = "All";

  public I_Brand = "All";
  public I_Item = "";
  public I_Group = "";

  get_Items() {

    this.loading = true;
    this.isload = true;
    this.Items_Rows = [];
    this.get("Api/Master/get_Items?Category=" + this.I_Category + "&Brand=" + this.I_Brand + "&Item=" + this.I_Item + "&Order_by=" + this.Item_Orderby_Name).subscribe((res: any) => {
      this.Items_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;


    });
  }






  Num(data) {
    return Number(data).toFixed(2);
  }




  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";

  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }



  public S_Department = "All";
  public header_Row: any = {}
  public Details_Row = [];
  public filter_data;

  public Temp_Order_Row = [];

  add_one: number = 1;
  remove_one: number = -1;

  public Tax_Type = "exclusive";
  public GST_Type = "local";
  public cart_item = [

  ];



  Ledger_Master_Rows = [];
  Reference_Rows = [];
  get_Reference() {

    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;

    });
  }




  open_pdf(data) {


    if (this.Bill_Format == "Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Sales_Bills_Tally_Foramt?" + parm, "_blank");

    }
    else if (this.Bill_Format == "Format2") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_Invoice_1?" + parm, "_blank");

    } else if (this.Bill_Format == "Format3") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_Invoice_2?" + parm, "_blank");

    }
    else if (this.Bill_Format == "Format4") {
      window.open(this.Server_URL + "report/Print_Sales_Bills?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }





  }
  public DC_Format = "Klisters";
  open_delivery(data) {


    if (this.DC_Format == "Klisters1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.DC_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Klisters_Delivery_Bill?" + parm, "_blank");

    }
    else if (this.DC_Format == "Klisters") {
      window.open(this.Server_URL + "report/Klisters_Delivery_Bill?Bill_No=" + data.DC_No + "&Company=" + this.Company, "_blank");

    }


  }
  open_pdf_Non_Tax(data) {


    if (this.NT_Bill_Format == "NT_Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Sales_Bills_Non_Tax?" + parm, "_blank");

    }
    else if (this.NT_Bill_Format == "Klisters") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Klisters_Non_Tax_Bill?" + parm, "_blank");


    }
  }
  public P_Invoice_Format = "Format1"
  proforma_pdf(data) {
    if (this.P_Invoice_Format == "Format1") {
      ``
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_P_Invoice_1?" + parm, "_blank");
    }
    else if (this.P_Invoice_Format == "Klisters") {
      window.open(this.Server_URL + "report/Klisters_Proforma_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");



    }
  }
  public Delivery_Format = "Format1"
  Delivery_pdf(data) {
    if (this.Delivery_Format == "Format2") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_P_Invoice_1?" + parm, "_blank");
    }
    else if (this.Delivery_Format == "Format1") {
      window.open(this.Server_URL + "report/Print_Customerwise?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");



    }
  }
  public PO_Format = "Format1";
  open_purchase_pdf(data) {
    if (this.PO_Format == "Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.PO_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&PO_No=" + data.PO_No;
      window.open(this.Server_URL + "PDF/Export_PO_1?" + parm, "_blank");

    }

    else if (this.PO_Format == "Klisters") {
      window.open(this.Server_URL + "report/Klisters_PO_Bill?PO_No=" + data.PO_No + "&Company=" + this.Company, "_blank");

    }


  }







  public S_Employee_Code = "";

  public S_Employee_Name = "";
  public Employee_Details_Rows = [];
  get_Employee_Details() {

    this.isload = true;
    this.Employee_Details_Rows = [];
    this.get("Api/Master/get_Employee_Details?Status=" + this.Emp_Status).subscribe((res: any) => {
      this.Employee_Details_Rows = JSON.parse(res).record;
      this.isload = false;

    });

  }


  getcolor(data) {
    if (data == 'P') {
      return "green_";
    }
    else if (data == 'A' || data == 'L') {
      return "red_";
    }
    else {
      return "";
    }


  }

  File_Name = "";
  pdfType = "Open";


  get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }







  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }



  public sum_of(dt, field) {

    var data = "";
    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0).toFixed(2);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0).toFixed(2);
      } catch { }
    }

    return len;
  }



  headers;


  export_pdf() {


    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }



  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }


  get_Token() {

    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];
          this.get_Field_Setting1();
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }


  get_Field_Setting1() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {

      if (res == "No_Licence") {
        this.router.navigate(['/invalid-licence']);
      }
      else {
        this.Field_Setting = JSON.parse(res).record;
        this.get_Reference1();
      }
    });


  }


  Prow = [];
  get_Reference1() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;

      this.Reference_Rows.sort((a, b) => a["label"].localeCompare(b["label"]))


      this.CREATED_BY = this.data.UM_User_Name;
      this.Rights_ID = this.data.UM_Rights;


      this.Emp_ID = this.data.Emp_ID;

      this.Emp_Name = this.data.UM_Full_Name;
      this.Edit_Enable=this.data.UM_Edit;

      this.get_Role_Rights(this.data.UM_Rights);

      try {
        this.Prow = this.Reference_Rows.filter(e => e.Ref_ID == "User_Role");

        this.Rights_Name = this.Prow.filter(e => e.RGV_iID.toLowerCase() == this.data.UM_Rights)[0].RGV_vDesciption;
        this.U_Rights = this.Rights_Name.toLowerCase();

        this.Header_Disp = this.data.CM_Name;

      } catch { }


    });
  }



}
