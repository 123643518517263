import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  data :any={
    'UM_Full_Name':''
  }
    constructor(public router:Router,public appservice:AppService) {
     
      if(this.getData())
      {
      this.data=JSON.parse(localStorage.getItem('User_Data'));
      }
    
      var Menu_Len =0
      try{
        Menu_Len=appservice.Label_Menu_Rows.length;
      }catch{}

      if(Menu_Len==0)
      {
       appservice.get_Token()
      }

     }

     
  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

     addReset() {
      this.appservice.isadd = "0";
      return this.router.navigate(['/change-password']);
    }

     lenth:number=0;

     logout()
     {
      localStorage.clear();
   
      this.appservice.Company="";
      this.router.navigate(['/'], { replaceUrl: true });
     }

    ngOnInit() {
    }

   
}
