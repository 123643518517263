import { Component, OnInit } from "@angular/core";
import { AppService } from 'src/app/app.service';
declare let $: any;

@Component({
    selector: "app-analytics",
    templateUrl: "./analytics.component.html",
    styleUrls: ["./analytics.component.scss"]
})
export class AnalyticsComponent implements OnInit {
    constructor(public appservice:AppService) {
        
   
    }

    ngOnInit() {
        
        
    }
}
